import { Injectable, Inject, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { HttpCacheService } from "./HttpCacheService";
// import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AlertService } from "../providers/AlertProvider";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    private cacheService: HttpCacheService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    public alertSerice: AlertService
  ) {}

  loaderStart() {
    try {
      this.ngxService.start();
    } catch (error) {
      console.log(error);
    }
  }
  loaderDismiss() {
    try {
      // this.loadingCntr.dismiss();
      this.ngxService.stop();
    } catch (error) {}
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // pass along non-cacheable requests and invalidate cache
    if (req.method !== "GET") {
      // console.log(`Invalidating cache: ${req.method} ${req.url}`);
      this.cacheService.invalidateCache();
      return next.handle(req);
    }

    // attempt to retrieve a cached response
    const cachedResponse: HttpResponse<any> = this.cacheService.get(req.url);

    // return cached response
    if (cachedResponse) {
      console.log(`Returning a cached response: ${cachedResponse.url}`);
      return of(cachedResponse);
    }
    // send request to server and add response to cache
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          console.log(`Adding item to cache: ${req.url}`);
          this.loaderStart();
          this.cacheService.put(req.url, event);
          if(req.url.includes('usermoduleextract')){
            console.log('dismiss',req.url)

            // in future any one get any issue please remove below line
            this.loaderDismiss();

          }
          else{
            this.loaderDismiss();
          }
        }
      }),
      catchError((err: any) => {
        let toasterMessage = "";
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            toasterMessage = err.error.message;
          } else {
            // server-side error
            toasterMessage = err.error.data.message;
          }
          this.loaderDismiss();
          switch (err.status) {
            case 401:
              this.alertSerice.warning(toasterMessage);
            case 403:
              this.alertSerice.warning(toasterMessage);
              break;
            case 404:
              this.alertSerice.error(toasterMessage);
              break;
            case 406:
              this.alertSerice.error(toasterMessage);
              break;
            case 500:
              this.alertSerice.error(toasterMessage);
              break;
            default:
              this.alertSerice.error(toasterMessage);
              break;
          }
        }
        return of(err);
      })
    );
  }
}
