import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, pipe, of } from "rxjs";
import {
  map,
  catchError,
  retryWhen,
  delay,
  take,
  tap,
  finalize,
} from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthenticationCheckService } from "../auth/authentication-check.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastClientService } from "../toast/toast-client.service";
import { AlertService } from "src/app/shared/providers/AlertProvider";
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  /**
   * @author kailash_dev
   * @param httpInterceptor
   * Whenever calling Api this interceptor will inject AUTH_Token and enable loader
   * < Write less & code more />
   */
  constructor(
    private auth: AuthenticationCheckService,
    public toast: ToastClientService,
    private ngxService: NgxUiLoaderService,
    public alertSerice: AlertService
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //Authentication by setting header with token value
    let token = this.auth.getAuthToken();
    // console.log(token);
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error, caught) => {
        //intercept the respons error and displace it to the console
        this.handleAuthError(error);
        return of(error);
      }) as any
    );

    // return next.handle(request).pipe(
    //   catchError((error: HttpErrorResponse) => {

    //     return throwError(errorMessage);
    //   }),
    //   finalize(() => {
    //     // this.loaderDismiss();
    //   })
    // );
  }
  loaderStart() {
    try {
      this.ngxService.start();
    } catch (error) {
      console.log(error);
    }
  }
  loaderDismiss() {
    try {
      // this.loadingCntr.dismiss();
      this.ngxService.stop();
    } catch (error) { }
  }
  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    console.log("handled error " + err.status);
    let toasterMessage = err.error.data.message;
    switch (err.status) {
      case 401:
        this.alertSerice.warning(toasterMessage);
      case 403:
        this.alertSerice.warning(toasterMessage);
        break;
      case 404:
        this.alertSerice.error(toasterMessage);
        break;
      case 406:
        this.alertSerice.error(toasterMessage);
        break;
      case 500:
        this.alertSerice.error(toasterMessage);
        break;
      default:
        this.alertSerice.error(toasterMessage);
        break;
    }

    this.clearSuccess();

    if(err.status === 422){
      this.toast.Error(err.error.data.message)
    }


    //handle your auth error or rethrow
    if (err.status === 401) {
      //navigate /delete cookies or whatever
      console.log("handled error " + err.status);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message);
    }
    throw err;
  }

  clearSuccess() {
    setTimeout(() => {
      this.alertSerice.clear(); // stop foreground spinner of the master loader with 'default' taskId
    }, 4000);
  }
}
